//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Mitigating health risk for fitness clubs',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Welcoming gym guests and employees with unknown health statuses presents risk - especially in active, fitness-oriented environments',
    descriptionColor: 'white-1',
    image: 'usecaseAthleticClub',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'green-1',
    bgcolor: 'blue-1',
    bggradient: 'tropical',
  },
  {
    title: 'Automated pre-arrival health symptom reporting',
    titleWeight: 'medium',
    description:
      'Enable guests and employees to report their health symptoms before arrival via SMS on their mobile device, and maintain an auditable log to defend against future legal or health risks',
    image: 'usecaseAthleticClubSolution',
    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'sponsorship',
  },
]
const featureData = {
  title: 'Inspire confidence that your gym is safe',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Customize assessment questions',
          colbgcolor: 'purple-1',
          iconname: 'clipboard',
          description:
            "Customize health assessment questions based on your facility's needs, including for group classes and daycare",
        },
        {
          title: 'Improve safety',
          colbgcolor: 'grayblue-1',
          iconname: 'bullseye',
          description:
            "Show your customers you're taking health precautions, and reduce risk in case of an adverse event",
        },
        {
          title: 'Reduce unnecessary interactions',
          colbgcolor: 'blue-1',
          iconname: 'chatbot',
          description:
            'Automate pre-arrival health screenings to reduce risk from unwell emnployees or guests in your facility',
        },
      ],
    },
  ],
}
const About = props => (
  <Layout>
    <Helmet
      title="Picohealth - Fitness Clubs - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your gym / fitness club',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium purple-1">
            Maintain guest &amp; employee health in club
          </h1>
          <p class="display-4">
            Reduce your reopening risk by encouraging your employees and guests
            to self-certify their health status before they enter your fitness
            club
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="purple-1"
      bottomclass="white-4"
      flip={false}
      lineclass="blue-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="red-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
